<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:45:45
 * @Description: 首页
-->
<template>
  <div class="home-index" >
    <div class="banner"></div>
    <!-- 公司简介start -->
    <div class="companyIntro">
      <div class="introTitle">
          <p>COMPANY PROFILE</p>
          <span>公司简介</span>
          <i></i>
      </div>
      <div class="introCon">
        <div class="introConbox">
          <div class="boxL left">
            <img :src="require('@/assets/img/index/logotext.png')" alt="">
            <p class="tip">专业的资产管理公司</p>
            <p class="introText">
              青岛玉龙鼎盛是一家专业的资产管理公司，致力于为中国的高成长企业及高净值人群提供全方位资本运营服务，公司自成立以来，各方面运作规范成熟，凭借丰富的资源与专业化服务，目前已与多家大型民企达成战略合作。通过产品的设计及完备的投后管理，帮助融资方实现股权、债权多样化的金融产品融资，为多家大型民企集团提供权益产品、私募债、定向融资工具、定向投资工具等多元化的融资服务。
            </p>
            <div class="introLogo">
              <img :src="require('@/assets/img/index/logo.png')" alt="">
            </div>
          </div>
          <div class="boxR right">
            <img :src="require('@/assets/img/index/floor.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介end -->
    <!-- 金牌发行人 start -->
    <div class="pusher-wrap" id="anchor_2">
      <div class="introTitle">
          <p>GOLD MEDAL ISSUE</p>
          <span>金牌发行人</span>
          <i></i>
      </div>
      <ul class="w1200 pusher">
        <li class="left pusher-card" v-for="(item,index) in issuerList" :key="index" @click="goDetail(item.issuerId)">
          <!-- <router-link :to="'/publisherDetail'+'?issuerId='+item.issuerId"> -->
          <div class="top-logo">
            <img :src="item.logo" alt="">
          </div>
          <div class="tit">{{item.issuerName}}</div>
          <div class="mini-icon" :id="'mini-icon-'+index">
            <div class="left" v-for="(el,i) in item.tagList" :key="i"><span>{{el}}</span></div>
          </div>
          <div class='content2'>{{item.briefIntroduction}}</div>
          <!-- </router-link> -->
        </li>
      </ul>
    </div>
    <!-- 金牌发行人 end -->
    <!-- 鼎盛优势start -->
    <div class="advantage" id="anchor_2">
      <div class="introTitle">
          <p>ADVANTAGE</p>
          <span>青岛玉龙鼎盛优势</span>
          <i></i>
      </div>
      <div class="advantageCon">
        <ul>
          <!-- <li>
            <div class="advantageList">
              <img :src="require('@/assets/img/index/icon1.png')" alt="">
              <div class="advListBot">
                <span class="icon1">行业研究</span>
                <p>公司团队深入研究宏观经济发展趋势，资本市场变化等，为公司战略发展及投资决策提供依据。</p>
              </div>
            </div>
          </li> -->
          <li>
            <div class="advantageList">
              <img :src="require('@/assets/img/index/icon2.png')" alt="">
              <div class="advListBot">
                <span class="icon2">合规风险管理</span>
                <p>公司建立覆盖业务全流程的合规管理制度和合规管理机制，有效识别合规风险，主动避免违规事件发生。内部大力推行合规文化建设，确保合规工作落实到位。
公司项目实施全程风险控制机制。主要风险控制贯穿项目筛选、投资决策、项目投资及投后管理的全过程。</p>
              </div>
            </div>
          </li>
          <li>
            <div class="advantageList">
              <img :src="require('@/assets/img/index/icon3.png')" alt="">
              <div class="advListBot">
                <span class="icon3">投后管理</span>
                <p>公司投后管理实施定期汇报制度和重大、突发事项预警机制；公司对已投项目的退出事项，通过制定相应的退出计划，由各部门协调配合完成投资项目的顺利退出。</p>
              </div>
            </div>
          </li>
          <li>
            <div class="advantageList">
              <img :src="require('@/assets/img/index/icon4.png')" alt="">
              <div class="advListBot">
                <span class="icon4">精细化运营</span>
                <p>建立专业的运营大数据系统，建立投资项目跟踪数据全方位指标体系。采用数字化运营管理机制，不断提高运营效率，为客户提供精准流畅的线上服务。</p>
              </div>
            </div>
          </li>
          <li>
            <div class="advantageList">
              <img :src="require('@/assets/img/index/icon1.png')" alt="">
              <div class="advListBot">
                <span class="icon4">金融创新能力</span>
                <p>能够充分利用市场各种投融资工具和策略，进行创新的整合和发展，运用互联网技术创新手段，以健全的风险管控体系为基础，为机构、企业与投资者搭建投融资平台，通过资产收益权转让、直接融资等金融产品、金融工具的创新，优化增量资金投向，促进实体经济发展。</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 鼎盛优势end -->
    <my-foot @changePage="changePage" id="anchor_3"/>
  </div>
</template>

<script>
import '../../assets/css/home/index.scss';
import myFoot from '@/components/myFoot/index.vue'
import { getIssuerList } from '@/web/home'
import { setTimeout, clearTimeout } from 'timers';
export default {
  name: 'index',
  data() {
    return {
      issuerList:[],
      iconList:[]
    }
  },
  components: {
    myFoot
  },
  created() {
    getIssuerList({ pageNo: 1, pageSize: 20 }).then((res) => {
      this.issuerList = res.list
      res.list.forEach((el,i) => {
        this.getHeight('mini-icon-',i)
      });
    })
    
  },
  methods: {
    changePage(i) {
      this.$store.commit('changePage', i) 
    },
    // 获取高度
    getHeight(name,id){
      let time = setTimeout(()=>{
        if(document.getElementById(name+id).clientHeight>60){//如果标签大于60高度，说明是两行标签
          this.iconList[id]=2;//两行标签，下面的内容就保留一行点点点
        }else{
          this.iconList[id]=1//一行标签，下面的内容就保留两行点点点
        };
        clearTimeout(time);
      },1000)
    },

    //跳转发行人详情
    goDetail( issuerId ){
      this.$router.push({path: '/publisherDetail', query: { issuerId: issuerId } })
      //切换后页面回到顶部
    }
  },
}
</script>


